<template>
  <div>
    <list
      ref="refProfileSalaryList"
      sort-by="PerfilIdiomaId"
      :refetch-records="fetchProfilesSalary"
      refetch-records-name="perfilesSalariales"
      :actions="actions"
      key-field="perfilSalarialId"
      :table-columns="tableColumns"
      :table-actions="tableActions"
      :delete-method="deleteProfileSalary"
      :filters.sync="filters"
    >
      <template #cell(tienePrestacionesDeLey)="data">
        <b-badge
          pill
          :variant="`light-${data.item.tienePrestacionesDeLey?'success':'danger'}`"
          class="text-capitalize w-50"
        >
          {{ data.item.tienePrestacionesDeLey ? $t('humanResources.profileSalary.columns.haveYes') : $t('humanResources.profileSalary.columns.haveNo') }}
        </b-badge>
      </template>
    </list>
  </div>
</template>
<script>

import { BBadge } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import List from '@/components/List.vue'
import humanResources from '@/services/humanresources.service'

export default {
  components: {
    List,
    BBadge,
  },
  setup() {
    /* Refs */
    const refProfileSalaryList = ref(null)
    /* Services */
    const {
      fetchProfilesSalary,
      deleteProfileSalary,
    } = humanResources()
    /* Data */
    const tableColumns = [
      {
        label: i18n.t('humanResources.profileSalary.columns.name'),
        key: 'nombre',
      },
      {
        label: i18n.t('humanResources.profileSalary.columns.baseSalary'),
        key: 'sueldoBase',
      },
      {
        label: i18n.t('humanResources.profileSalary.columns.haveBenefits'),
        key: 'tienePrestacionesDeLey',
      },
      {
        label: i18n.t('Lists.Actions'),
        key: 'actions',
      },
    ]
    const tableActions = ref([
      {
        name: 'edit',
        label: i18n.t('Lists.Edit'),
        aclAction: 'create',
        aclResource: 'Perfil',
        routeName: 'apps-human-resources-profile-salary-edit',
        params: ['perfilSalarialId'],
        icon: 'CodepenIcon',
      },
      {
        name: 'delete',
        label: i18n.t('Lists.Delete'),
        aclAction: 'delete',
        aclResource: 'Perfil',
        params: ['perfilSalarialId'],
        icon: 'TrashIcon',
      },
    ])
    const actions = ref([
      {
        routeName: 'apps-human-resources-profile-salary-add',
        label: i18n.t('Routes.ProfileSalaryAdd'),
        aclAction: 'create',
        aclResource: 'Perfil',
        icon: 'PlusCircleIcon',
      },
    ])
    const filters = ref([])
    return {
      refProfileSalaryList,
      tableColumns,
      tableActions,
      actions,
      filters,
      fetchProfilesSalary,
      deleteProfileSalary,
    }
  },
}
</script>
